import type { CacheControl } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { useDynamicPageQueryKey } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { getDynamicPageQueryKey } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { logger } from '@/core/features/logger/logger';
import { useQueryClient } from '@/core/features/react-query/react-query-service';
import { useRouterEvents } from '@/core/features/router/router-events';
import { atom, useSetAtom } from '@/core/features/store/atom-store';

export const cacheRefetchUrlStateAtom = atom<null | string>(null);

export default function useDynamicPageQueryCacheHandler({
    cacheControl,
    identifier,
    isCachedResponse,
    isDynamicPageCacheDisabled,
}: {
    cacheControl: CacheControl | null;
    identifier: null | string;
    isCachedResponse: boolean;
    isDynamicPageCacheDisabled: boolean;
}): void {
    const isInitialized = React.useRef(false);
    const identifierRef = React.useRef<null | string>(null);
    const queryClient = useQueryClient();
    const routerEvents = useRouterEvents();
    const [clientUrl] = useClientUrl();
    const dynamicPageQueryKey = useDynamicPageQueryKey();

    const setCacheRefetchUrl = useSetAtom(cacheRefetchUrlStateAtom);
    const cacheRefetchTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const refetchQuery = React.useCallback(
        async ({ onFinish, onSuccess, url }: { onFinish?: () => void; onSuccess?: () => void; url?: string } = {}) => {
            const queryKey = getDynamicPageQueryKey(url ?? clientUrl);
            try {
                await queryClient.refetchQueries({ exact: true, queryKey });
                onSuccess?.();
                routerEvents.emit('refetchComplete');
            } catch (error) {
                logger.error('Error refetching query', {
                    additionalData: JSON.stringify({
                        error,
                        queryClient,
                    }),
                    url: url ?? clientUrl,
                });
            } finally {
                onFinish?.();
            }
        },
        [clientUrl, queryClient, routerEvents],
    );

    const clearCacheRefetchTimeout = React.useCallback(() => {
        setCacheRefetchUrl(null);
        if (cacheRefetchTimeoutRef.current) {
            clearTimeout(cacheRefetchTimeoutRef.current);
            cacheRefetchTimeoutRef.current = null;
        }
    }, [setCacheRefetchUrl]);

    React.useEffect(() => {
        routerEvents.on('refetch', refetchQuery);
        return () => {
            routerEvents.off('refetch', refetchQuery);
        };
    }, [refetchQuery, routerEvents]);

    React.useEffect(() => {
        if (cacheControl === 'no-cache' && identifier !== null && identifier !== identifierRef.current) {
            // Skip invalidation on first render after ssr
            // Prefetched responses during SSR get passed to client
            // 'no-cache' immediately invalidates these on client thus triggering unnecessary refetches
            if (isInitialized.current) {
                queryClient.invalidateQueries({ queryKey: dynamicPageQueryKey });
            }

            identifierRef.current = identifier;
            isInitialized.current = true;
        }
        if (identifier !== null && identifier !== identifierRef.current) {
            identifierRef.current = identifier;
        }
    }, [cacheControl, identifier, queryClient, dynamicPageQueryKey]);

    // automatically refetch query client-side when response was cached
    React.useEffect(() => {
        if (isDynamicPageCacheDisabled) {
            return;
        }
        clearCacheRefetchTimeout();

        if (!isCachedResponse || cacheRefetchTimeoutRef.current) {
            return;
        }

        setCacheRefetchUrl(clientUrl);

        cacheRefetchTimeoutRef.current = setTimeout(() => {
            refetchQuery({
                onFinish: () => {
                    clearCacheRefetchTimeout();
                },
            });
        }, 5000);

        return () => {
            clearCacheRefetchTimeout();
        };
    }, [
        clearCacheRefetchTimeout,
        clientUrl,
        isCachedResponse,
        isDynamicPageCacheDisabled,
        refetchQuery,
        setCacheRefetchUrl,
    ]);
}
