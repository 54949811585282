import type { OverlayHistoryStateRecord } from '@/core/features/modals/overlay-history/overlay-history-state';
import type { ScrollHistoryState } from '@/core/features/scroll/page-scroll-restoration/scroll-history';

import debounce from 'lodash.debounce';

import { IS_SERVER, NEXT_PUBLIC_CHECK24_DOMAIN } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';
import SessionStorage from '@/core/features/store/session-storage';
import { removeUndefinedAndNullValues } from '@/core/utils/object';
import { TIME_10M } from '@/core/utils/time';
import { getUrlPathWithSearch } from '@/core/utils/url';

export type HistoryState = {
    as: string;
    isBackNavigation?: true;
    isFirstAppVisit?: true;
    key: string;
    loginLayerOpen?: boolean;
    mapHeight?: number;
    options: { locale: 'de' };
    overlayHistoryStateRecord?: OverlayHistoryStateRecord;
    scrollHistoryState?: ScrollHistoryState;
    url: string;
};

type HistoryHandler = (url: string, state?: Partial<HistoryState>) => void;

const DEBOUNCE_TIMING = 50;

export const historySessionStorage = new SessionStorage<unknown>('history_fallback', {
    defaultKey: '',
    defaultTTL: TIME_10M * 2,
});

// do not use as is, always use historyReplaceDebounced to prevent multiple triggers causing multiple requests
const historyReplace = (url: string, state?: Partial<HistoryState>): void => {
    if (IS_SERVER) {
        logger.error('historyReplace is not supported server side');
        return;
    }

    const newState = updateHistoryState(url, state);

    history.replaceState(newState, '', url);
};

export const historyReplaceDebounced: HistoryHandler = debounce(
    (url, state) => historyReplace(getUrlPathWithSearch(url), state),
    DEBOUNCE_TIMING,
);

export const historyPush = (url: string, state?: Partial<HistoryState>): void => {
    if (IS_SERVER) {
        logger.error('historyPush is not supported server side');
        return;
    }

    const newState = updateHistoryState(url, {
        ...state,
        isFirstAppVisit: undefined, // remove on any push
    });

    history.pushState(newState, '', url);
};

export const historyPushState = (state: Partial<HistoryState>): void =>
    historyPush(getUrlPathWithSearch(location.href), state);

export const historyReplaceState = (state: Partial<HistoryState>) =>
    historyReplace(getUrlPathWithSearch(location.href), state);

export const historyBack = (): void => {
    if (IS_SERVER) {
        logger.error('historyBack is not supported server side');
        return;
    }

    history.back();
};

/* eslint-disable fp/no-mutation */
const updateHistoryState = (url: string, state?: Partial<HistoryState>) => {
    const newState = { ...history.state };

    if (newState.url) {
        newState.url = url;
    }
    if (newState.as) {
        newState.as = url;
    }

    const historyState = removeUndefinedAndNullValues({
        ...newState,
        ...state,
        isBackNavigation: state?.isBackNavigation ?? undefined,
    });

    return historyState;
};
/* eslint-enable fp/no-mutation */

export const isHistoryBackSupported = () => {
    // explicit back navigation was set
    if (history.state.isBackNavigation === true) {
        return false;
    }
    // page was opened fresh or from a link that was our own location
    if (
        (document.referrer === '' || document.referrer.includes(NEXT_PUBLIC_CHECK24_DOMAIN ?? location.origin)) &&
        history.length > 1
    ) {
        return true;
    }
    // state is marked as fresh
    if (isFirstAppVisit()) {
        return false;
    }
    // enough history entries available
    return history.length > 2;
};

export const getHistoryStateProp = <T>(prop: keyof HistoryState): T | null => {
    if (!history.state) {
        return null;
    }
    return history.state[prop] ?? null;
};

const isFirstAppVisit = (): boolean => {
    if (IS_SERVER) {
        return true;
    }

    if (!history.state) {
        return true;
    }

    return history.state.isFirstAppVisit === true;
};

export const setFirstAppVisit = () => {
    historyReplaceState({ isFirstAppVisit: true });
};
