import type { ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';

import { getFallbackSsrProps } from '@/core/features/app/ssr-props';
import { isAndroidDevice, isIosDevice, isMacDevice as isMacDevice } from '@/core/features/cookies/cookies-service';
import { atom, createStore, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';

const fallbackSsrProps = getFallbackSsrProps('/');

export const clientUrlAtom = atom('uninitialized');
export const cookiesMappedAtom = atom(fallbackSsrProps.cookiesMapped);
export const layoutIdAtom = atom<ILayoutId | null>(null);
const ssrUrlAtom = atom('uninitialized');
const cookiesAtom = atom(fallbackSsrProps.cookies);
const userAtom = atom(fallbackSsrProps.user);
const isIosAtom = atom(false);
const isMacAtom = atom(false);
const isAndroidAtom = atom(false);

export const createInitialAppAtomsStore = (ssrProps: SsrProps) => {
    const store = createStore();

    store.set(clientUrlAtom, ssrProps.ssrUrl);
    store.set(cookiesMappedAtom, ssrProps.cookiesMapped);
    store.set(layoutIdAtom, ssrProps.layoutId);

    store.set(ssrUrlAtom, ssrProps.ssrUrl);
    store.set(cookiesAtom, ssrProps.cookies);
    store.set(userAtom, ssrProps.user);
    store.set(isIosAtom, isIosDevice(ssrProps.cookiesMapped.deviceinfo, ssrProps.cookiesMapped.indiSession));
    store.set(isMacAtom, isMacDevice(ssrProps.cookiesMapped.deviceinfo, ssrProps.cookiesMapped.indiSession));
    store.set(isAndroidAtom, isAndroidDevice(ssrProps.cookiesMapped.deviceinfo, ssrProps.cookiesMapped.indiSession));

    return store;
};

export const useClientUrl = () => useAtom(clientUrlAtom);
export const useSetClientUrl = () => useSetAtom(clientUrlAtom);
export const useCookiesMapped = () => useAtomValue(cookiesMappedAtom);
export const useLayoutId = () => useAtomValue(layoutIdAtom);

export const useSsrUrl = () => useAtomValue(ssrUrlAtom);
export const useCookies = () => useAtomValue(cookiesAtom);
export const useUser = () => useAtom(userAtom);
export const useIsIos = () => useAtomValue(isIosAtom);
export const useIsAndroid = () => useAtomValue(isAndroidAtom);
export const useIsMac = () => useAtomValue(isMacAtom);
