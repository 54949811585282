import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';
import type { CookiesMapped } from '@/core/features/cookies/cookies-service';

import { getDeviceInfo, getDeviceOSMapped, getDeviceoutputFromCookies } from '@/core/features/cookies/cookies-service';
import { getURL } from '@/core/utils/url';

const ADJUST_TRACKER = '19qm4rr9';
const ADJUST_FALLBACK = 'https://www.check24.de/app/';
const INDI_PRODUCTION_HOST = 'individualreisen.check24.de';
const unsupportedDeviceBrands = ['Huawei'];

// Docs: https://confluence.check24.de/pages/viewpage.action?spaceKey=C24MOCO&title=How+To%3A+Generate+Adjust+Links
export function getAdjustLink(
    deepLinkUrl: string,
    tracking: DynamicPageMeta['trackingMeta'] | null,
    cookiesMapped: CookiesMapped,
): string {
    const parsedDeepLinkUrl = getURL(deepLinkUrl);
    addTrackingParameters(parsedDeepLinkUrl, tracking);
    const targetLinkBase = `${INDI_PRODUCTION_HOST}${parsedDeepLinkUrl.pathname}${parsedDeepLinkUrl.search}`;
    const deepLink = `c24-app-vergleiche://${targetLinkBase}`;

    const { deviceOS } = getDeviceInfo(cookiesMapped.deviceinfo, cookiesMapped.indiSession);
    const mappedOS = getDeviceOSMapped(deviceOS);

    if (mappedOS === 'android') {
        const adjustDeepLink = getURL(`https://app.adjust.com/${ADJUST_TRACKER}`);
        adjustDeepLink.searchParams.set('deep_link', deepLink);
        adjustDeepLink.searchParams.set('fallback', ADJUST_FALLBACK);
        return adjustDeepLink.href;
    }

    const adjustUniversalLink = getURL(`https://9a6e.adj.st/${targetLinkBase}`);
    adjustUniversalLink.searchParams.set('adjust_t', ADJUST_TRACKER);
    adjustUniversalLink.searchParams.set('adjust_deep_link', deepLink);
    adjustUniversalLink.searchParams.set('adjust_fallback', ADJUST_FALLBACK);

    return adjustUniversalLink.href;
}

const addTrackingParameters = (url: URL, tracking: DynamicPageMeta['trackingMeta'] | null): URL => {
    const { pid, tid, wpset } = tracking ?? {};

    if (wpset) {
        url.searchParams.set('wpset', wpset);
    }
    if (tid) {
        url.searchParams.set('tid', tid);
    }
    if (pid) {
        url.searchParams.set('pid', pid);
    }

    return url;
};

export const isAppPromotionAvailable = ({ cookiesMapped, meta, okResponse }: SsrProps): boolean => {
    if (!okResponse) {
        return false;
    }

    const { deviceName, deviceOS } = getDeviceInfo(cookiesMapped.deviceinfo, cookiesMapped.indiSession);

    const isDisabled = !!meta?.settings?.['disableAppBanner'];

    if (isDisabled) {
        return false;
    }

    const isMobile = getDeviceoutputFromCookies(cookiesMapped) === 'mobile';

    if (!isMobile) {
        return false;
    }

    const isDeviceBrandSupported = !unsupportedDeviceBrands.some((deviceBrand) => deviceName.includes(deviceBrand));

    if (!isDeviceBrandSupported) {
        return false;
    }

    const mappedOS = getDeviceOSMapped(deviceOS);
    const isDeviceOSSupported = mappedOS !== 'other';

    if (!isDeviceOSSupported) {
        return false;
    }

    return true;
};

export const isAppSplashAvailable = (ssrProps: SsrProps): boolean => {
    if (ssrProps.userAgent.includes('Lighthouse') || ssrProps.ssrUrl.includes('pagespeed')) {
        return false;
    }

    const { isBot } = getDeviceInfo(ssrProps.cookiesMapped.deviceinfo, ssrProps.cookiesMapped.indiSession);

    if (isBot) {
        return false;
    }

    if (!!ssrProps.cookiesMapped.c24splash) {
        return false;
    }

    if (!!ssrProps.cookiesMapped.indiSplash) {
        return false;
    }

    if (getURL(ssrProps.ssrUrl).searchParams.has('travelToggle')) {
        return false;
    }

    return isAppPromotionAvailable(ssrProps);
};
