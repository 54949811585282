import type { HistoryState } from '@/core/features/router/history';
import type { HistoryChangeContext } from '@/core/features/router/router-events';

import React from 'react';

import debounce from 'lodash.debounce';

import { REQUEST_TRACKING_HEADER } from '@/constants/request-headers';
import { useSetClientUrl } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { triggerAppNativeLink, willForwardToAppNative } from '@/core/features/link/app-native-link-forward-service';
import { useInView } from '@/core/features/react-intersection-observer/react-intersection-observer-service';
import { historyPush, historyReplaceDebounced } from '@/core/features/router/history';
import { useRouterEvents } from '@/core/features/router/router-events';
import useIsScrolling from '@/core/features/scroll/use-is-scrolling';
import { atom, useAtom, useAtomValue } from '@/core/features/store/atom-store';
import { getURL, isExternalUrl } from '@/core/utils/url';

export type LinkEvent = React.MouseEvent<HTMLAnchorElement, Event>;
export type LinkClickInterface = (event: LinkEvent) => boolean | void;

export type RouterLinkProps = React.PropsWithChildren<
    React.AnchorHTMLAttributes<HTMLAnchorElement> & {
        href: string;
        isMutation?: boolean;
        onClick?: LinkClickInterface;
        prefetchConfig: {
            delay?: number;
            enabled: boolean;
            handler?: (href: string) => void;
        };
        qaId?: string;
    }
>;

const activeRouterNavigationUrlAtom = atom<null | string>(null);
export const useRouterNavigatingState = () => !!useAtomValue(activeRouterNavigationUrlAtom);

export const RouterLink = (props: RouterLinkProps) => {
    const { href, isMutation, onClick, prefetchConfig, qaId, ...anchorTagProps } = props;

    const { navigate } = useRouterLink();
    const prefetchRef = useRouterLinkPrefetch(href, prefetchConfig);

    const _onClick = (event: LinkEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        const canNavigate = onClick?.(event);

        // check again as onClick could preventDefault as well
        if (event.defaultPrevented) {
            return;
        }

        // we handle the link event, therefore prevent here
        event.preventDefault();

        // onClick might not have event availble to preventDefault
        // here onClick explicitly returned false to prevent the navigation
        if (canNavigate === false) {
            return;
        }

        // if user has ctrl/cmd key pressed, open a new tab
        if (event.ctrlKey || event.metaKey) {
            // ctrlKey = windows, metaKey = Mac
            window.open(props.href, '_blank');
            return;
        }

        if (props.target === '_blank') {
            window.open(props.href, '_blank');
            return;
        }

        // Warning: By coupling link-click-tracking and prefetching, tracking is not possible with prefetching disabled
        navigate(href, { disableLinkClickTracking: !prefetchConfig.enabled, isMutation });
    };

    return (
        <a
            data-qa-id={qaId}
            href={href}
            onClick={_onClick}
            ref={prefetchRef}
            {...anchorTagProps}
        >
            {props.children}
        </a>
    );
};

export type RouterLinkNavigateHandler = (
    url: string,
    options?: {
        disableLinkClickTracking?: boolean;
        disableScrollHistory?: boolean; // using navigate + historyReplace will save the scroll position, this can be disabled with this option
        forceHrefNavigation?: boolean;
        historyState?: Partial<HistoryState>;
        historyType?: 'historyPush' | 'historyReplace';
        isMutation?: boolean;
        isUrlRewrite?: boolean;
        target?: '_blank';
    },
) => void;

export const useRouterLink = (options?: { matchingUrl: string }) => {
    const setClientUrl = useSetClientUrl();
    const [activeRouterNavigation, setActiveRouterNavigation] = useAtom(activeRouterNavigationUrlAtom);
    const { isApp } = useDeviceoutput();
    const { matchingUrl } = options ?? {};

    const routerEvents = useRouterEvents();
    const navigationTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const clearNavigationTimeout = () => {
        if (navigationTimeoutRef.current !== null) {
            clearTimeout(navigationTimeoutRef.current);
        }
    };

    const navigate: RouterLinkNavigateHandler = React.useCallback(
        (navigateUrl, options) => {
            const {
                disableScrollHistory,
                historyState,
                historyType = 'historyPush',
                isUrlRewrite = false,
            } = options ?? {};
            routerEvents.emit('routeChangeStart');
            routerEvents.emit<HistoryChangeContext>('historyChange', {
                disableScrollHistory,
                historyType,
                isUrlRewrite,
                navigateUrl,
            });

            setActiveRouterNavigation(navigateUrl);

            if (isExternalUrl(navigateUrl)) {
                const absoluteLinkUrl = getURL(navigateUrl).href;
                window.open(absoluteLinkUrl, options?.target);
                return;
            }

            if (isApp && willForwardToAppNative(navigateUrl)) {
                return triggerAppNativeLink(navigateUrl);
            }

            routerEvents.on('routeChangeComplete', () => setActiveRouterNavigation(null));
            routerEvents.on('refetchComplete', () => setActiveRouterNavigation(null));

            // Navigating takes too long or got canceled
            navigationTimeoutRef.current = setTimeout(() => {
                setActiveRouterNavigation(null);
            }, 10000);

            if (historyType === 'historyPush') {
                historyPush(navigateUrl, historyState);
            } else if (historyType === 'historyReplace') {
                historyReplaceDebounced(navigateUrl, historyState);
            }

            if (!options?.disableLinkClickTracking) {
                trackRouterLinkClick(navigateUrl);
            }

            if (options?.forceHrefNavigation) {
                window.location.assign(navigateUrl);
                return;
            }

            if (options?.isMutation) {
                routerEvents.emit('refetch', { onSuccess: () => setClientUrl(navigateUrl), url: navigateUrl });
                return;
            }
            setClientUrl(navigateUrl);
            clearNavigationTimeout();
        },
        [isApp, routerEvents, setActiveRouterNavigation, setClientUrl],
    );

    React.useEffect(() => {
        return () => {
            clearNavigationTimeout();
            setActiveRouterNavigation(null);
            routerEvents.off('routeChangeComplete', () => setActiveRouterNavigation(null));
            routerEvents.off('refetchComplete', () => setActiveRouterNavigation(null));
        };
    }, [routerEvents, setActiveRouterNavigation]);

    return {
        isNavigating:
            matchingUrl === activeRouterNavigation || (matchingUrl === null && activeRouterNavigation !== null),
        navigate,
    };
};

const useRouterLinkPrefetch = (href: string, prefetchConfig: RouterLinkProps['prefetchConfig']) => {
    const { inView, ref } = useInView({
        skip: !prefetchConfig.enabled,
    });
    const isScrolling = useIsScrolling({ debounceTimer: 100, unlockTimer: 200 });

    const prefetchTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const clearPrefetchTimeout = () => {
        if (!prefetchTimeoutRef.current) {
            return;
        }
        clearTimeout(prefetchTimeoutRef.current);
    };

    React.useEffect(() => {
        clearPrefetchTimeout();

        if (prefetchConfig.enabled && prefetchConfig.handler && inView && !isScrolling) {
            prefetchTimeoutRef.current = setTimeout(() => {
                prefetchConfig.handler?.(href);
            }, prefetchConfig.delay ?? 0);
        }

        return () => {
            clearPrefetchTimeout();
        };
    }, [href, inView, isScrolling, prefetchConfig]);

    return ref;
};

// noop, just fetch the url with a header to let the api know the user clicked
// do not use this function directly, use debouncedRouterLinkClick instead to prevent hooks triggering it multiple times
const UNSAFE__trackRouterLinkClick = (url: string) => {
    fetch(url, { headers: REQUEST_TRACKING_HEADER }).catch((_error) => {
        // logger.warn('Failed to track router link click', {
        //     additionalData: typeof error === 'object' ? JSON.stringify({ error }) : `${error}`,
        //     url,
        // });
    });
};

const trackRouterLinkClick = debounce(UNSAFE__trackRouterLinkClick, 300);
