import type {
    DynamicPage_jsonld_page_read,
    NestedPageComponent,
    PageComponent,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { DynamicPageError } from '@/core/features/a-dynamic-page/dynamic-page-types';
import type { QueryClient, QueryKey } from '@/core/features/react-query/react-query-service';
import type { RouterLinkNavigateHandler } from '@/core/features/router/router-link';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { isNestedComponent } from '@/core/features/a-component/services/component-service';
import logger from '@/core/features/logger/logger';
import { clientSideApiGetRequest } from '@/core/features/request/client-side-request';
import { historyReplaceDebounced } from '@/core/features/router/history';
import { getUrlPath, getUrlSearchParams, removeTrailingQuestionmark } from '@/core/utils/url';

export type DynamicPageResponse = DynamicPage_jsonld_page_read;
export type DynamicPageData = { cached: boolean; response: DynamicPage_jsonld_page_read };
type DynamicPageErrorData = { cached: boolean; response: DynamicPageError };

const INDI_API_PATH_DYNAMIC_PAGE = 'dynamic-page';
const NEXT_API_PATH_DYNAMIC_PAGE = '/dynamic-page';

export const getDynamicPageQueryKey = (url: string): QueryKey => {
    return [INDI_API_PATH_DYNAMIC_PAGE, removeTrailingQuestionmark(url)];
};

export const updateDynamicPage = async ({
    clientUrl,
    disableScrollHistory,
    isUrlRewrite,
    navigate,
    newUrl,
    queryClient,
    setClientUrl,
}: {
    clientUrl: string;
    disableScrollHistory?: boolean; // using navigate + historyReplace will save the scroll position, this can be disabled with this option
    isUrlRewrite?: boolean; // used to check if the scroll restoration history state needs to be updated to match the new url
    navigate: RouterLinkNavigateHandler;
    newUrl: string;
    queryClient: QueryClient;
    setClientUrl: (url: string) => void;
}): Promise<void> => {
    try {
        const queryKey = getDynamicPageQueryKey(newUrl);

        // SSR might have already hydrated the state (e.g. for overlay=pending in checkout or direct visits, e.g. /tauchen?activity=10)
        const hydratedState = await queryClient.getQueryData<DynamicPageData>(queryKey);

        const result =
            hydratedState ??
            (await queryClient.fetchQuery<DynamicPageData>({
                queryFn: ({ signal }) => getDynamicPageClientSide({ abortSignal: signal, url: newUrl }),
                queryKey,
            }));

        if (!result) {
            logger.error('Error in updateDynamicPage: no result');
            return;
        }

        if (result.response.url !== clientUrl) {
            setDynamicPageRedirectData({ queryClient, result });
        }

        navigate(result.response.url, { disableScrollHistory, historyType: 'historyReplace', isUrlRewrite });
    } catch (error) {
        // revert url change
        setClientUrl(clientUrl);
        historyReplaceDebounced(clientUrl);
        logger.error(`Error in updateDynamicPage: ${typeof error === 'object' ? JSON.stringify(error) : error}`, {
            additionalData: JSON.stringify({
                clientUrl,
                disableScrollHistory,
                isUrlRewrite,
                newUrl,
            }),
        });
    }
};

/**
 * handle rewrite data when API changes the URL, e.g. /skifarhen?activity=9 becomes /wandern
 * seed the query cache so we do not make another request
 */
export const setDynamicPageRedirectData = ({
    queryClient,
    result,
}: {
    queryClient: QueryClient;
    result: DynamicPageData;
}) => {
    const newQueryKey = getDynamicPageQueryKey(result.response.url);
    queryClient.setQueryData(newQueryKey, result);
};

export const isDynamicPageData = (data: unknown): data is DynamicPageData => {
    return (
        !!data &&
        (data as DynamicPageData).cached !== undefined &&
        (data as DynamicPageData).response !== undefined &&
        isDynamicPageResponse((data as DynamicPageData).response)
    );
};

export const isDynamicPageResponse = (data: unknown): data is DynamicPageResponse => {
    return !!data && (data as DynamicPageResponse).components !== undefined;
};

export const isDynamicPageErrorData = (data: unknown): data is DynamicPageErrorData => {
    return !!data && (data as DynamicPageErrorData).response.code !== undefined;
};

export const isDynamicPageErrorResponse = (data: unknown): data is DynamicPageError => {
    return !!data && (data as DynamicPageError).code !== undefined;
};

export const isDynamicPageRedirectErrorResponse = (
    error: unknown,
): error is { response: { data: { redirectUrl: string } } } => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _error = error as any;
    try {
        const redirectUrl = _error.response.data.redirectUrl;
        return !!redirectUrl;
    } catch (e) {
        return false;
    }
};

export const getDynamicPageClientSide = async ({
    abortSignal,
    headers,
    url,
}: {
    abortSignal?: AbortSignal;
    headers?: Record<string, string>;
    url: string;
}): Promise<DynamicPageData> => {
    const pathname = getUrlPath(url);

    const searchParams = getUrlSearchParams(url);

    const requestUrl = getDynamicPageApiRequestUrl(pathname, searchParams);

    const response = await clientSideApiGetRequest<DynamicPageData>(requestUrl, abortSignal, headers);

    return response.data;
};

const getDynamicPageApiRequestUrl = (pathname: string, searchParams?: URLSearchParams) => {
    let searchString = '';
    let path = '';
    if (searchParams) {
        const searchParamsString = searchParams ? `?${searchParams.toString()}` : '';
        if (searchParamsString !== '?') {
            // eslint-disable-next-line fp/no-mutation
            searchString = searchParamsString;
        }
    }
    if (pathname !== '/') {
        // eslint-disable-next-line fp/no-mutation
        path = pathname;
    }

    return `${NEXT_API_PATH_DYNAMIC_PAGE}${path}${searchString}`;
};

export const mergeDynamicPageComponentsWithPartialComponents = (
    pageComponents: PageComponent[],
    updatedPartialComponents: PageComponent[],
): PageComponent[] => {
    return pageComponents.map((pageComponent) => {
        if (isNestedComponent(pageComponent)) {
            const updatedNastedComponent =
                pageComponent.loading === 'partial'
                    ? ((updatedPartialComponents.find((partialComponent) => {
                          if (!isNestedComponent(partialComponent)) {
                              return false;
                          }
                          return matchPartialComponent(partialComponent, pageComponent);
                          // typescript can not infer that the component is a NestedPageComponent here
                      }) as NestedPageComponent | undefined) ?? pageComponent)
                    : pageComponent;

            return {
                ...updatedNastedComponent,
                attributes: {
                    ...updatedNastedComponent.attributes,
                    components:
                        updatedNastedComponent.attributes?.components?.map((nestedComponent) => {
                            return (
                                updatedPartialComponents.find((partialComponent) => {
                                    return matchPartialComponent(partialComponent, nestedComponent);
                                }) ?? nestedComponent
                            );
                        }) ?? [],
                },
            } as NestedPageComponent; // type inference fails here because GroupV1 uses title and not htmlTitle - can be ignored for now
        }
        return (
            updatedPartialComponents.find((partialComponent) => {
                return matchPartialComponent(partialComponent, pageComponent);
            }) ?? pageComponent
        );
    });
};

const matchPartialComponent = (partialComponent: PageComponent, component: PageComponent) => {
    /**
     * Client and server cookies may differ, affecting componentIris and causing mismatches.
     * To avoid this, we only use the componetId here, as it has no dependencies on the cookies.
     */
    return getComponentId(component['@id']) === getComponentId(partialComponent['@id']);
};
