import type { SsrProps } from '@/core/features/app/ssr-props';
import type { AppProps as NextSsrProps } from 'next/app';

import React from 'react';

import dynamic from 'next/dynamic';

import '@/assets/global-styles/colors.css';
import GlobalCss from '@/assets/global-styles/global-css';
import '@/assets/global-styles/util-classes.css';
import '@/assets/global-styles/z-index.css';
import { ErrorBoundaryWrapper } from '@/core/features';
import { createQueryClient } from '@/core/features/a-dynamic-page/dynamic-page-query-client';
import AppContextWrapper from '@/core/features/app/app-context-wrapper';
import { mapSsrProps } from '@/core/features/app/ssr-props';
import { isAppSplashAvailable } from '@/core/features/app-promotion/app-promotion-service';
import LazyHydrateOn from '@/core/features/lazy/lazy-hydrate-on';
import '@/core/features/logger/console';
import HeadDynamic from '@/core/features/page/head-dynamic';
import { Hydrate, QueryClientProvider } from '@/core/features/react-query/react-query-service';
import { useWireframeConsentBannerVisibleListener } from '@/core/features/wireframe/use-wireframe-consent-banner-visible';
import WireframeSwitch from '@/core/features/wireframe/wireframe-switch';

const HiddenMenu = dynamic(
    () => import(/* webpackChunkName: "HiddenMenu" */ '@/core/features/hidden-menu/hidden-menu'),
);
const AppSplash = dynamic(() => import(/* webpackChunkName: "AppSplash" */ '@/core/features/app-promotion/app-splash'));

export default function App({ Component, pageProps, router }: NextSsrProps<SsrProps>) {
    const [queryClient] = React.useState(createQueryClient(router.asPath));

    const ssrProps: SsrProps = mapSsrProps(router.asPath, pageProps);

    const showConsent = useWireframeConsentBannerVisibleListener(ssrProps.cookiesMapped, ssrProps.okResponse);

    const showHiddenMenu =
        pageProps.isInternalIp && !pageProps.isHiddenMenuDisabled && !ssrProps.ssrUrl.includes('pagespeed');

    if (ssrProps.isWidget) {
        return (
            <QueryClientProvider client={queryClient}>
                <Hydrate state={ssrProps.dehydratedState}>
                    <AppContextWrapper {...ssrProps}>
                        <HeadDynamic
                            {...ssrProps}
                            isGoogleTagManagerDisabled={showConsent}
                        />
                        {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                        <Component {...pageProps} />
                    </AppContextWrapper>
                </Hydrate>
            </QueryClientProvider>
        );
    }

    if (ssrProps.isStaticRoute) {
        return (
            <QueryClientProvider client={queryClient}>
                <Hydrate state={ssrProps.dehydratedState}>
                    <ErrorBoundaryWrapper>
                        <AppContextWrapper {...ssrProps}>
                            <HeadDynamic
                                {...ssrProps}
                                isGoogleTagManagerDisabled={showConsent}
                            />
                            {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                            {ssrProps.isWireframeDisabled ? (
                                <Component {...pageProps} />
                            ) : (
                                <>
                                    <GlobalCss />
                                    <WireframeSwitch
                                        {...ssrProps}
                                        showConsent={showConsent}
                                    >
                                        <Component {...pageProps} />
                                    </WireframeSwitch>
                                </>
                            )}
                        </AppContextWrapper>
                    </ErrorBoundaryWrapper>
                </Hydrate>
            </QueryClientProvider>
        );
    }

    // note: it is important to render this here, because LazyHydrateOn will stop all necessary JS running inside of AppSplashWrapper
    const renderAppSplash = isAppSplashAvailable(ssrProps);

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={ssrProps.dehydratedState}>
                <ErrorBoundaryWrapper>
                    <AppContextWrapper {...ssrProps}>
                        <HeadDynamic
                            {...ssrProps}
                            isGoogleTagManagerDisabled={showConsent}
                        />
                        {renderAppSplash && <AppSplash {...ssrProps} />}
                        {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                        <GlobalCss />
                        {ssrProps.isWireframeDisabled ? (
                            <LazyHydrateOn
                                condition={!showConsent}
                                htmlRootTag={'main'}
                            >
                                <Component {...ssrProps} />
                            </LazyHydrateOn>
                        ) : (
                            <WireframeSwitch
                                {...ssrProps}
                                showConsent={showConsent}
                            >
                                <LazyHydrateOn
                                    condition={!showConsent}
                                    htmlRootTag={'main'}
                                >
                                    <Component {...ssrProps} />
                                </LazyHydrateOn>
                            </WireframeSwitch>
                        )}
                    </AppContextWrapper>
                </ErrorBoundaryWrapper>
            </Hydrate>
        </QueryClientProvider>
    );
}
