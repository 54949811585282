/* eslint-disable no-inline-styles/no-inline-styles */
import type { SsrProps } from '@/core/features/app/ssr-props';

import React from 'react';

import dynamic from 'next/dynamic';

import { NEXT_PUBLIC_CHECK24_DOMAIN } from '@/constants/env';
import { useClientUrl, useLayoutId } from '@/core/features/app/app-atoms';
import { WIREFRAME_HEADER_HEIGHT } from '@/core/features/wireframe/constants';
import useWireframeLogin from '@/core/features/wireframe/use-wireframe-login';
import { getURL, getUrlPath } from '@/core/utils/url';

const HeaderTitle = () => (
    <div
        className={'text-white bold absolute'}
        style={{ left: 'calc(50vw - 44px)', top: 12 }}
    >
        Sportreisen
    </div>
);

const WireframeHeaderMobilePlaceholder = () => {
    return (
        <header
            className={'placeholder bg-catalinaBlue'}
            style={{
                height: WIREFRAME_HEADER_HEIGHT,
                width: '100vw',
            }}
        >
            <HeaderTitle />
        </header>
    );
};

const WireframeHeaderMobileWrapper = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeHeaderMobileWrapper" */ '@/core/features/wireframe/wireframe-header-mobile-wrapper'
        ),
    { loading: WireframeHeaderMobilePlaceholder },
);

const WireframeConsentMobile = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeConsentMobile" */ '@/core/features/wireframe/generated/wireframe-consent-mobile'
        ),
);

const WireframeFooterMobile = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeFooterMobile" */ '@/core/features/wireframe/generated/wireframe-footer-mobile'
        ),
);

const WireframeTapBarMobile = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeTapBarMobile" */ '@/core/features/wireframe/generated/wireframe-tap-bar-mobile'
        ),
);

const WireframeConsentDesktop = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeConsentDesktop" */ '@/core/features/wireframe/generated/wireframe-consent-desktop'
        ),
);
const WireframeHeaderDesktop = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeHeaderDesktop" */ '@/core/features/wireframe/generated/wireframe-header-desktop'
        ),
);
const CustomerSupportOverlay = dynamic(
    () => import(/* webpackChunkName: "WireframeHeaderDesktop" */ '@/core/features/wireframe/customer-support-overlay'),
);
const WireframeJsAssetDesktop = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeHeaderDesktop" */ '@/core/features/wireframe/generated/wireframe-js-asset-desktop'
        ),
);
const WireframeCssAssetDesktop = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeHeaderDesktop" */ '@/core/features/wireframe/generated/wireframe-css-asset-desktop'
        ),
);
const WireframeFooterDesktop = dynamic(
    () =>
        import(
            /* webpackChunkName: "WireframeFooterDesktop" */ '@/core/features/wireframe/generated/wireframe-footer-desktop'
        ),
);

export default function WireframeSwitch(ssrProps: React.PropsWithChildren<SsrProps & { showConsent: boolean }>) {
    const { deviceoutput } = ssrProps.cookiesMapped;
    const [clientUrl] = useClientUrl();
    const href = getURL(clientUrl).toString();
    const desktopLinkUrl = getURL(clientUrl);
    desktopLinkUrl.searchParams.set('deviceoutput', 'desktop');

    const layoutId = useLayoutId();

    useWireframeLogin();

    if (!NEXT_PUBLIC_CHECK24_DOMAIN) {
        return null;
    }

    switch (deviceoutput) {
        case 'desktop':
        case 'tablet':
        case 'tabletapp': {
            const isApp = deviceoutput === 'tabletapp';

            return (
                <>
                    {ssrProps.showConsent && <WireframeConsentDesktop />}
                    <WireframeCssAssetDesktop />
                    {!isApp ? (
                        <WireframeHeaderDesktop
                            assetComponent={
                                !ssrProps.showConsent && (
                                    <WireframeJsAssetDesktop
                                        deviceoutput={deviceoutput}
                                        layoutId={layoutId}
                                    />
                                )
                            }
                            check24Domain={NEXT_PUBLIC_CHECK24_DOMAIN}
                            contactLayerComponent={<CustomerSupportOverlay />}
                            href={href}
                            loginStatus={ssrProps.user.loginStatus}
                            navigationComponent={null}
                            username={ssrProps.user.username}
                        />
                    ) : (
                        <WireframeJsAssetDesktop
                            deviceoutput={deviceoutput}
                            layoutId={layoutId}
                        />
                    )}

                    {
                        /**
                         * note: when consent is visible we do not hydrate the app
                         * when hydration is disabled, nothing can follow after the app (props.children) or a hydration error is shown
                         * so we need to move the footer up
                         */
                        ssrProps.showConsent ? (
                            <>
                                <div style={{ bottom: -400, height: 20, left: 0, position: 'absolute', right: 0 }}>
                                    <WireframeFooterDesktop />
                                </div>
                                {ssrProps.children}
                            </>
                        ) : (
                            <>
                                {ssrProps.children}
                                <WireframeFooterDesktop />
                            </>
                        )
                    }
                </>
            );
        }
        case 'mobile':
        case 'app':
        default: {
            const isApp = deviceoutput === 'app';
            const showTapBar = getUrlPath(clientUrl) === '/' && !isApp && !ssrProps.appError;

            return (
                <>
                    {ssrProps.showConsent ? (
                        <>
                            {!isApp && <WireframeHeaderMobilePlaceholder />}
                            <WireframeConsentMobile />
                            <div style={{ bottom: -100, height: 20, left: 0, position: 'absolute', right: 0 }}>
                                {/**
                                 * note: when consent is visible we do not hydrate the app
                                 * when hydration is disabled, nothing can follow after the app (props.children)  or a hydration error is shown
                                 * so we need to render the footer above and move it out of view
                                 */}
                                <WireframeFooterMobile desktopLink={desktopLinkUrl.toString()} />
                            </div>
                            {ssrProps.children}
                        </>
                    ) : (
                        <>
                            <WireframeHeaderMobileWrapper
                                headerTitleComponent={<HeaderTitle />}
                                ssrProps={ssrProps}
                            />
                            {ssrProps.children}
                            <WireframeTapBarMobile tabBarClassName={showTapBar ? '' : 'hidden'} />
                            <WireframeFooterMobile desktopLink={desktopLinkUrl.toString()} />
                        </>
                    )}
                </>
            );
        }
    }
}
