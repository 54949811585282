import type { DynamicPageMeta, ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { DynamicPageError } from '@/core/features/a-dynamic-page/dynamic-page-types';
import type { CookiesMapped, CookiesUnmapped } from '@/core/features/cookies/cookies-service';
import type { DehydratedState } from '@/core/features/react-query/react-query-service';
import type { AxiosUIData } from '@/core/features/request/axios-ui';
import type { UserValidationProperties } from '@/core/features/user/user-validation-type';
import type { AppRating } from '@/core/features/wireframe/wireframe-app-rating-cache';

import { getAllMappedCookieValuesFallback } from '@/core/features/cookies/cookies-service';
import { getGuestResponse } from '@/core/features/user/user-validation-guest-service';

export type SsrProps = {
    appError: DynamicPageError | null;
    appRating: AppRating | null;
    axiosUIData?: AxiosUIData;
    cookies: CookiesUnmapped;
    cookiesMapped: CookiesMapped;
    dehydratedState: DehydratedState;
    isDynamicPageCacheDisabled: boolean;
    isErrorRoute: boolean;
    isHiddenMenuDisabled: boolean;
    isInternalIp: boolean;
    isStaticRoute: boolean;
    isWidget: boolean;
    isWireframeDisabled: boolean;
    layoutId: ILayoutId | null;
    meta: DynamicPageMeta | null;
    okResponse: boolean;
    ssrUrl: string;
    user: UserValidationProperties;
    userAgent: string;
    wireframeBackUrl: null | string;
};

type SsrPropsMaybe = Partial<SsrProps>; // props are undefined for 500/400 pages

// handle static routes and fallback data
export const mapSsrProps = (route: string, ssrPropsMaybe: SsrPropsMaybe): SsrProps => {
    if (ssrPropsMaybe.isErrorRoute || !ssrPropsMaybe.cookies) {
        return getFallbackSsrProps(route, ssrPropsMaybe);
    }
    return ssrPropsMaybe as SsrProps;
};

export const getFallbackSsrProps = (route: string, ssrPropsMaybe?: SsrPropsMaybe): SsrProps => {
    return {
        appError: null,
        appRating: null,
        cookies: ssrPropsMaybe?.cookies ?? {},
        cookiesMapped: ssrPropsMaybe?.cookiesMapped ?? getAllMappedCookieValuesFallback(),
        dehydratedState: {} as never,
        isDynamicPageCacheDisabled: true,
        isErrorRoute: ssrPropsMaybe?.isErrorRoute ?? false,
        isHiddenMenuDisabled: false,
        isInternalIp: ssrPropsMaybe?.isInternalIp ?? false,
        isStaticRoute: ssrPropsMaybe?.isErrorRoute ?? true,
        isWidget: false,
        isWireframeDisabled: false,
        layoutId: null,
        meta: null,
        okResponse: ssrPropsMaybe?.okResponse ?? false,
        ssrUrl: route,
        user: getGuestResponse(),
        userAgent: ssrPropsMaybe?.userAgent ?? '',
        wireframeBackUrl: null,
    };
};
