import React from 'react';

import { atom, useAtom } from '@/core/features/store/atom-store';

type RouterEventName =
    | 'historyChange'
    | 'linkout'
    | 'popstate'
    | 'refetch'
    | 'refetchComplete'
    | 'routeChangeComplete'
    | 'routeChangeStart'
    | 'routerInit';

const routerEventListenersAtom = atom<Record<RouterEventName, Set<Function>>>({
    historyChange: new Set([]),
    linkout: new Set([]),
    popstate: new Set([]),
    refetch: new Set([]),
    refetchComplete: new Set([]),
    routeChangeComplete: new Set([]),
    routeChangeStart: new Set([]),
    routerInit: new Set([]),
});

export type RouteChangeContext = { isPopstate: boolean };
export type RouteChangeEvent = (context: RouteChangeContext) => void;
export type HistoryChangeContext = {
    disableScrollHistory?: boolean; // historyReplace will save the scroll position, this can be disabled with this option
    historyType: 'historyPush' | 'historyReplace';
    isUrlRewrite?: boolean; // when a url rewrite is happening we need to update the saved scroll position
    navigateUrl: string;
};

export const useRouterEvents = () => {
    const [listeners, setListeners] = useAtom(routerEventListenersAtom);

    const events = React.useRef({
        emit: <TEvent>(eventName: RouterEventName, event?: TEvent) => {
            listeners[eventName].forEach((listener) => {
                listener(event);
            });
        },
        off: (eventName: RouterEventName, listener: Function) => {
            listeners[eventName].delete(listener);
        },
        on: (eventName: RouterEventName, listener: Function) => {
            listeners[eventName].add(listener);
            setListeners(listeners);
        },
    });

    return events.current;
};
