import type { DeviceoutputMapped } from '@/core/features/cookies/cookies-service';
import type { UserValidationProperties } from '@/core/features/user/user-validation-type';
import type { IUnifiedLoginResponseData } from '@/core/features/wireframe/wireframe-types';

import React from 'react';

import { NEXT_PUBLIC_CHECK24_DOMAIN, NEXT_PUBLIC_SSO_PRODUCT_ID } from '@/constants/env';
import { useClientUrl, useUser } from '@/core/features/app/app-atoms';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { savePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/scroll-history';
import BrowserStorage from '@/core/features/store/browser-storage';
import SessionStorage from '@/core/features/store/session-storage';
import { WIREFRAME_ULI_LOGIN_LAYER_EVENT, WIREFRAME_UNIFIED_LOGIN_EVENT } from '@/core/features/wireframe/constants';
import useWireframeLoaded from '@/core/features/wireframe/use-wireframe-loaded';
import { TIME_1S } from '@/core/utils/time';
import { getURL } from '@/core/utils/url';

const userSessionStorage = new SessionStorage<UserValidationProperties & { count: number }>('login', {
    defaultKey: '',
});

export default function useWireframeLogin() {
    const { deviceoutputMapped, isApp } = useDeviceoutput();
    const [user, setUser] = useUser();

    const saveScrollPositionOnLoginLayerOpen = useWireframeScrollRestoration();

    useWireframeLoaded(() => {
        if (isApp) {
            return;
        }
        initializeLoginLayer(deviceoutputMapped);
        saveScrollPositionOnLoginLayerOpen();
    });

    React.useEffect(() => {
        const updateAndSetUser = () => updateUser(user).then(setUser);

        // Update user on app login
        addEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, updateAndSetUser);

        // Update user on mobile/desktop login
        addEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, updateAndSetUser);

        // Update user on route transition (clientUrl changes)
        updateAndSetUser();

        return () => {
            removeEventListener(WIREFRAME_UNIFIED_LOGIN_EVENT, updateAndSetUser);
            removeEventListener(WIREFRAME_ULI_LOGIN_LAYER_EVENT, updateAndSetUser);
        };
    }, [setUser, user]);
}

const updateUser = (user: UserValidationProperties): Promise<UserValidationProperties> => {
    if (!BrowserStorage.isSupported(sessionStorage)) {
        return Promise.resolve(user);
    }

    const now = Date.now();
    const sessionUserData = userSessionStorage.get();

    const updatedTimestamp = Math.max(user.updatedTimestamp, sessionUserData?.updatedTimestamp ?? 0);

    if (now - updatedTimestamp < TIME_1S * 3) {
        // do not refetch data that is still fresh
        return Promise.resolve(user);
    }

    userSessionStorage.set({
        ...user,
        count: (sessionUserData?.count ?? 0) + 1,
        updatedTimestamp: now,
    });

    return fetch('/api/sso/validate-user') //
        .then((response) => response.json());
};

const initializeLoginLayer = (deviceoutput: DeviceoutputMapped): void => {
    if (!window.Check24) {
        throw 'window.Check24 is not initialized';
    }

    if (window.Check24.uliloginlayer.initialized) {
        return;
    }

    if (!NEXT_PUBLIC_SSO_PRODUCT_ID || !NEXT_PUBLIC_CHECK24_DOMAIN) {
        throw new Error(
            'NEXT_PUBLIC_SSO_PRODUCT_ID, NEXT_PUBLIC_CHECK24_DOMAIN  are not defined during Wireframe LoginLayer initialization',
        );
    }

    // eslint-disable-next-line fp/no-mutation
    window.Check24.uliloginlayer.closeDefaultAfter = (): void => {
        if (!window.Check24) {
            return;
        }
        // eslint-disable-next-line fp/no-loops, fp/no-mutation
        for (let i = 0; i < window.Check24.uliloginlayer.historyCount; i++) {
            history.back();
        }
    };

    // eslint-disable-next-line fp/no-mutation
    window.Check24.uliloginlayer.closeAfter = (data: IUnifiedLoginResponseData | undefined): void => {
        if (data?.LOGINTYPE === undefined || data?.LOGINTYPE === '') {
            return;
        }

        window.location.reload();
    };

    window.Check24.uliloginlayer.init({
        api_product: NEXT_PUBLIC_SSO_PRODUCT_ID,
        deviceoutput,
        login_email: '',
        login_phone: '',
        login_type: '',
        social_login_base_url: `https://kundenbereich.${NEXT_PUBLIC_CHECK24_DOMAIN}/`,
        social_login_callback_url: encodeURIComponent(window.location.href),
        social_login_closing_url: encodeURIComponent(getLoginClosingUrl()),
    });
};

const getLoginClosingUrl = (): string => {
    const { host, origin, protocol } = window.location;

    return getURL('/login-closing', origin || `${protocol}//${host}`).toString();
};

const useWireframeScrollRestoration = () => {
    const [clientUrl] = useClientUrl();
    const originalLoginLayerOpen = React.useRef<(e?: Event) => void>();

    const saveScrollPositionOnLoginLayerOpen = () => {
        if (!window.Check24) {
            return;
        }
        originalLoginLayerOpen.current ??= window.Check24.uliloginlayer.open;

        // eslint-disable-next-line fp/no-mutation
        window.Check24.uliloginlayer.open = (e?: Event) => {
            savePageScrollPosition(clientUrl);
            originalLoginLayerOpen.current?.(e);
        };
    };

    return saveScrollPositionOnLoginLayerOpen;
};
