/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-this */

import type { BrowserStorageOptions } from '@/core/features/store/browser-storage';

import BrowserStorage from '@/core/features/store/browser-storage';

type StorageName =
    | 'conversion_tracking'
    | 'history_fallback'
    | 'login'
    | 'school_product_event_swiper'
    | 'search'
    | 'slider'
    | 'spot_tile_active_tab'
    | 'swiper_slide'
    | 'wishlist';

/* eslint-disable fp/no-class */
export default class SessionStorage<T> extends BrowserStorage<T> {
    constructor(storeName: StorageName, options?: BrowserStorageOptions) {
        super('sessionStorage', storeName, options);
    }
}
